import VideoModal from "../partials/VideoModal";

class Home{

    constructor () {
        //Variables

        //Bind
       
        // Listener

        //Init
        this.init();
        setTimeout(()=>{
            this.animBanner();
        },500)
    }

    init() {
        $('.js-dashboard').slick({
            slidesToShow: 6,
            autoplay: true,
            autoplaySpeed: 0,
            speed: 5000,
            cssEase:'linear',
            infinite: true,
            focusOnSelect: false,
            arrows: false,
            responsive: [{
                breakpoint: 1200,
                settings: {
                    slidesToShow: 5
                }
            }, {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4
                }
            }, {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3
                }
            }, {
                breakpoint: 400,
                settings: {
                    slidesToShow: 2
                }
            }]
        });
    }

    // Top banner animation
    animBanner() {
        let shape1 = document.querySelector(".js-shape-1");
        let shape2 = document.querySelector(".js-shape-2");
        let shape3 = document.querySelector(".js-shape-3");

        const animHome = new TimelineLite({paused : true});
        animHome.to(shape1, 1.2, {css:{right:'15%', bottom:'-40%'}, ease:'Power1.easeOut'}, 0);
        animHome.to(shape2, 0.6, {css:{right:'18%', bottom:'-35%', rotation:90}, ease:'Power1.easeOut'}, 0);
        animHome.to(shape2, 0.6, {css:{right:'36%', bottom:'20%', rotation:0}, ease:'Power1.easeOut'}, 0.5);
        animHome.to(shape3, 0.6, {css:{right:'0%', bottom:'0'}, ease:'Power1.easeOut'}, 0);
        animHome.to(shape3, 0.6, {css:{right:'16.5%', bottom:'26%'}, ease:'Power1.easeOut'}, 0.5);
        animHome.restart();
    }
}

new Home();