class VideoModal{

    constructor() {
        //Variables
        this.ctaPopup = document.querySelectorAll(".js-open-video");
        this.ctaClosePopup = document.querySelector(".js-close-video");
        this.popupVideo = document.querySelector(".js-popup-video");
        this.playerVideo = document.querySelector(".js-player-video");

        //Bindn
        this.openPopup = this.openPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);

        //Listeners
        if(this.ctaPopup.length > 0) {
            this.ctaPopup.forEach(pop => pop.addEventListener("click", this.openPopup));
            this.ctaClosePopup.addEventListener("click", this.closePopup);
        }
    }

    openPopup(e) {
        TweenMax.to(this.popupVideo, 0.3, { css: { autoAlpha: 1 }, ease: 'Power1.easeOut' });
        if (e.currentTarget.dataset.id) {
            let id = e.currentTarget.dataset.id;
            this.playerVideo.innerHTML = '<iframe width="560" height="315" src="https://www.youtube.com/embed/' + id + '?autoplay=1&enablejsapi=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
        } else {
            let videoSrcWebm = e.currentTarget.getAttribute('data-src-webm');
            let videoSrcMp4 = e.currentTarget.getAttribute('data-src-mp4');
            this.playerVideo.innerHTML = '<video controls autoplay><source src='+videoSrcWebm+' type="video/webm"><source src='+videoSrcMp4+' type="video/mp4"></video>';
        }
    }

    closePopup() {
        TweenMax.to(this.popupVideo, 0.3, { css: { autoAlpha: 0 }, ease: 'Power1.easeOut' });
        this.playerVideo.innerHTML = '';
    }

}

new VideoModal();